import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import MaterialDatabase from './pages/MaterialDatabase/MaterialDatabase';
import MaterialList from './pages/MaterialDatabase/MaterialList';
import HardnessComparison from './pages/MaterialDatabase/HardnessComparison';
import MaterialForm from './pages/MaterialDatabase/MaterialForm';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/materials" element={<MaterialDatabase />}>
          <Route index element={<MaterialList />} />
          <Route path="listofmaterials" element={<MaterialList />} />
          <Route path="hardnesscomparison" element={<HardnessComparison />} />
          <Route path="materialform" element={<MaterialForm />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
