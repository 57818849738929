import "./HardnessComparison.css";
import hardnessComparison from "./data/hardnessComparison";
import React, { useState } from 'react';

const HardnessComparison = () => {
  const [selectedHardness, setSelectedHardness] = useState('MPa');
  const [hardnessValue, setHardnessValue] = useState('');
  const [hardnessIndex, setHardnessIndex] = useState('');

  const displayMinMaxValues = () => {
    const values = hardnessComparison[selectedHardness];
    if (!values) {
      return 'Min: N/A, Max: N/A';
    }
    const numericValues = values.filter(value => typeof value === 'number');
  
    const minValue = Math.min(...numericValues);
    const maxValue = Math.max(...numericValues);
  
    return `Min: ${minValue}, Max: ${maxValue}`;
  };
 
  const handleHardnessIndex = (newHardnessValue) => {
    const values = hardnessComparison[selectedHardness];
    if (!values) {
      console.error('Hardness table not found');
      return;
    }
    let foundIndex = -1;
    for (let i = 0; i < values.length; i++) {
      if (values[i] >= newHardnessValue) {
        foundIndex = i;
        break;
      }
    }
    setHardnessIndex(foundIndex);
  };


  const handleHardnessChange = (event) => {
    setSelectedHardness(event.target.value);
    handleHardnessIndex(parseFloat(hardnessValue));
  };

  const handleValueChange = (event) => {
    const newHardnessValue = event.target.value;
    setHardnessValue(newHardnessValue);
    handleHardnessIndex(parseFloat(newHardnessValue));
  };


  return (
    <div className="hardness-comparison">
      <div className="input-field">
        <div className="select-field">
          <select value={selectedHardness} onChange={handleHardnessChange}>
            {Object.keys(hardnessComparison).map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))}
          </select>
          <p>{displayMinMaxValues()}</p>
      </div>
        <input
          type="number"
          value={hardnessValue}
          onChange={handleValueChange}
          placeholder="Enter hardness value"
        />
      </div>
      <div className="result-field">
        {
          Object.keys(hardnessComparison).map((key) => {
          
            return (
              <div key={key}>
                <p>{key}: {hardnessComparison[key][hardnessIndex]}</p>
                
              </div>
            );
          })
        }
      </div>

    </div>
  );
};

export default HardnessComparison;