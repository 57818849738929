import React, { useState, useEffect } from "react";
import MaterialDetail from "./MaterialDetail";
import 'bootstrap/dist/css/bootstrap.min.css';
import './MaterialList.css';
import axios from 'axios';

const MaterialList = ({ onMaterialSelect }) => {
    const [materials, setMaterials] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [page, setPage] = useState(1);
    const [searchString, setSearchString] = useState('');
    const limit = 8;

    useEffect(() => {
        const loadData = async () => {
            const materials = await fetchMaterials(page, limit, searchString);
            setMaterials(materials);
        };
        loadData();
    }, [page, searchString]);

    const handleNextPage = (e) => {
        e.preventDefault();
        setPage(prevPage => prevPage + 1);
    };

    const handlePrevPage = (e) => {
        e.preventDefault();
        setPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleMaterialClick = (material) => {
        setSelectedMaterial(material);
        if (onMaterialSelect) {
            onMaterialSelect(material);
        }
    };

    const handleCloseDetail = () => {
        setSelectedMaterial(null);
    };

    const handleSearchChange = (e) => {
        const input = e.target.value;
        setSearchString(input);
        setPage(1); // Reset page number when searching
    };

    const updateMaterials = async () => {
        const materials = await fetchMaterials(page, limit, searchString);
        setMaterials(materials);
    };

    return (
        <div>
            {!selectedMaterial && (
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search your material..."
                    value={searchString}
                    onChange={handleSearchChange}
                />
            </div>
            )}
            <div className="materials-container">
                {selectedMaterial ? (
                    <MaterialDetail material={selectedMaterial} onClose={handleCloseDetail} onUpdate={updateMaterials} />
                ) : (
                    materials.map((material) => (
                        <div key={material._id} className="material-item" onClick={() => handleMaterialClick(material)}>
                            <h2>{material.name}</h2>
                            <h3>{material.materialName}</h3>
                            <div className="other-names">
                                {material.otherNames.map((name, index) => (
                                    <div key={index}>{name}</div>
                                ))}
                            </div>
                        </div>
                    ))
                )}
            </div>
            {!selectedMaterial && (
                <div className="lists-buttons">
                    <button onClick={handlePrevPage} disabled={page === 1}>Previous</button>
                    <button onClick={handleNextPage}>Next</button>
                </div>
            )}
        </div>
    );
};

const fetchMaterials = async (page = 1, limit = 10, searchString = '') => {
    try {
        console.log('Fetching materials with params:', { page, limit, searchString }); // Debug info
        const params = {
            page: page,
            limit: limit
        };

        const database = 'materialdatabase'; // Zde zadejte název vaší databáze
        const collection = 'materialData'; // Zde zadejte název vaší kolekce

        let response;
        const baseUrl = "https://asijolab-bd37131ba451.herokuapp.com/api/materials"; // Zde zadejte URL vašeho Heroku backendu

        if (searchString) {
            params.searchString = searchString;
            response = await axios.get(`${baseUrl}/${database}/${collection}/search`, { params });
        } else {
            response = await axios.get(`${baseUrl}/${database}/${collection}`, { params });
        }

        return response.data;
    } catch (error) {
        console.error('Error fetching materials:', error);
        return [];
    }
};

export default MaterialList;
