import './MaterialForm.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const MaterialForm = () => {
  const location = useLocation();
  const selectedMaterial = location.state?.material;

  const initialMaterialData = {
    materialName: '',
    otherNames: [],
    tensileStrength: '',
    thermalConductivity: '',
    elongation: '',
    chemicalComposition: [],
    materialGroup: '',
    coefficients: { Vc: "", Fz: "", Ap: "", Ae: "" },
    materialDescription: '',
    isChecked: false,
  };

  const [materialData, setMaterialData] = useState(initialMaterialData);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (selectedMaterial) {
      setMaterialData(selectedMaterial);
      setIsEditing(true);
    }
  }, [selectedMaterial]);

  const addOtherName = () => {
    setMaterialData({
      ...materialData,
      otherNames: [...materialData.otherNames, '']
    });
  };

  const removeOtherName = (index) => {
    const newOtherNames = materialData.otherNames.filter((_, i) => i !== index);
    setMaterialData({
      ...materialData,
      otherNames: newOtherNames
    });
  };

  const handleOtherNameChange = (index, value) => {
    const newOtherNames = materialData.otherNames.map((name, i) => {
      if (i === index) {
        return value;
      }
      return name;
    });
    setMaterialData({
      ...materialData,
      otherNames: newOtherNames
    });
  };

  const addChemicalComposition = () => {
    setMaterialData({
      ...materialData,
      chemicalComposition: [...materialData.chemicalComposition, { element: '', percentage: '' }]
    });
  };

  const removeChemicalComposition = (index) => {
    const newChemicalComposition = materialData.chemicalComposition.filter((_, i) => i !== index);
    setMaterialData({
      ...materialData,
      chemicalComposition: newChemicalComposition
    });
  };

  const handleChemicalCompositionChange = (index, field, value) => {
    const newChemicalComposition = materialData.chemicalComposition.map((composition, i) => {
      if (i === index) {
        return { ...composition, [field]: value };
      }
      return composition;
    });
    setMaterialData({
      ...materialData,
      chemicalComposition: newChemicalComposition
    });
  };

  const submitMaterialData = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const { _id, ...updates } = materialData;
    try {
      const database = 'materialdatabase';
      const collection = 'materialData';
      if (isEditing) {
        if (_id) {
          const response = await axios.put(
            `/api/materials/${database}/${collection}/${_id}`,
            updates
          );
          if (response.status === 200) {
            console.log(`Material with ID ${_id} successfully updated.`);
            alert('Material successfully updated.');
            setMaterialData(initialMaterialData);
            setIsEditing(false);
          } else {
            console.error(`Failed to update material with ID ${_id}. Status code: ${response.status}`);
          }
        } else {
          console.error('Material ID is missing for editing.');
        }
      } else {
        const response = await axios.post(
          `/api/materials/${database}/${collection}`, 
          materialData
        );
        if (response.status === 201) {
          console.log('Material successfully created.');
          alert('Material successfully created.');
          setMaterialData(initialMaterialData);
        } else {
          console.error(`Failed to create material. Status code: ${response.status}`);
        }
      }
    } catch (error) {
      console.error('Error submitting material data:', error);
      alert('There was an error submitting the material data. Please try again.');
    }
  };

  return (
    <form onSubmit={submitMaterialData}>
      <h2>Material Form</h2>
        <div className="group-all-other">
          <div className="group-material-info">
            <label htmlFor="materialName">Material name:</label>
            <input
              type="text"
              id="materialName"
              name="materialName"
              value={materialData.materialName}
              onChange={(e) => setMaterialData({ ...materialData, materialName: e.target.value })}
              placeholder="Material Name"
              className="margin-bottom"
            />

            <label htmlFor="tensileStrength">Tensile strength:</label>
            <input
              type="number"
              id="tensileStrength"
              name="tensileStrength"
              value={materialData.tensileStrength}
              onChange={(e) => setMaterialData({ ...materialData, tensileStrength: e.target.value })}
              placeholder="Tensile Strength"
              className="margin-bottom"
            />

            <label htmlFor="thermalConductivity">Thermal conductivity:</label>
            <input
              type="number"
              id="thermalConductivity"
              name="thermalConductivity"
              value={materialData.thermalConductivity}
              onChange={(e) => setMaterialData({ ...materialData, thermalConductivity: e.target.value })}
              placeholder="Thermal Conductivity"
              className="margin-bottom"
            />

            <label htmlFor="elongation">Elongation:</label>
            <input
              type="number"
              id="elongation"
              name="elongation"
              value={materialData.elongation}
              onChange={(e) => setMaterialData({ ...materialData, elongation: e.target.value })}
              placeholder="Elongation"
              className="margin-bottom"
            />

            <label htmlFor="chemicalComposition">Chemical composition:</label>
            {materialData.chemicalComposition.map((composition, index) => (
              <div key={index}>
                <input
                  type="text"
                  value={composition.element}
                  onChange={(e) => handleChemicalCompositionChange(index, 'element', e.target.value)}
                  placeholder="Element"
                />
                <input
                  type="number"
                  value={composition.percentage}
                  onChange={(e) => handleChemicalCompositionChange(index, 'percentage', e.target.value)}
                  placeholder="Percentage"
                />
                <button type="button" className="margin-bottom" onClick={() => removeChemicalComposition(index)}>Remove</button>
              </div>
            ))}
            <button type="button" onClick={addChemicalComposition}>Add Chemical Composition</button>
          </div>

          <div className="group-other-names-coefficients">
            <label htmlFor="otherNames">Other names:</label>
            {materialData.otherNames.map((name, index) => (
              <div key={index}>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => handleOtherNameChange(index, e.target.value)}
                  placeholder="Other Name"
                />
                <button type="button" className="margin-bottom" onClick={() => removeOtherName(index)}>Remove</button>
              </div>
            ))}
            <button type="button" className="margin-bottom" onClick={addOtherName}>Add Other Name</button>

            <label htmlFor="materialGroup">Material group:</label>
            <input
              type="number"
              id="materialGroup"
              name="materialGroup"
              value={materialData.materialGroup}
              onChange={(e) => setMaterialData({ ...materialData, materialGroup: e.target.value })}
              placeholder="Material Group"
              className="margin-bottom"
            />

            <label htmlFor="Coefficients">Coefficients:</label>
            <label htmlFor="Vc">Vc:</label>
            <input
              type="number"
              id="Vc"
              name="Vc"
              value={materialData.coefficients?.Vc || ''}
              onChange={(e) => setMaterialData({ ...materialData, coefficients: { ...materialData.coefficients, Vc: parseFloat(e.target.value) } })}
              placeholder="Coefficient Vc"
              className="margin-bottom"
            />
            <label htmlFor="Fz">Fz:</label>
            <input
              type="number"
              id="Fz"
              name="Fz"
              value={materialData.coefficients?.Fz || ''}
              onChange={(e) => setMaterialData({ ...materialData, coefficients: { ...materialData.coefficients, Fz: parseFloat(e.target.value) } })}
              placeholder="Coefficient Fz"
              className="margin-bottom"
            />
            <label htmlFor="Ap">Ap:</label>
            <input
              type="number"
              id="Ap"
              name="Ap"
              value={materialData.coefficients?.Ap || ''}
              onChange={(e) => setMaterialData({ ...materialData, coefficients: { ...materialData.coefficients, Ap: parseFloat(e.target.value) } })}
              placeholder="Coefficient Ap"
              className="margin-bottom"
            />
            <label htmlFor="Ae">Ae:</label>
            <input
              type="number"
              id="Ae"
              name="Ae"
              value={materialData.coefficients?.Ae || ''}
              onChange={(e) => setMaterialData({ ...materialData, coefficients: { ...materialData.coefficients, Ae: parseFloat(e.target.value) } })}
              placeholder="Coefficient Ae"
              className="margin-bottom"
            />
          </div>
        </div>

        <div className="group-material-description">
          <label htmlFor="materialDescription">Material description:</label>
          <textarea
            id="materialDescription"
            name="materialDescription"
            value={materialData.materialDescription || ''}
            onChange={(e) => setMaterialData({ ...materialData, materialDescription: e.target.value })}
            placeholder="Material Description"
            rows="4"
          />
        </div>

        <div className="group-admin-check">
          <label htmlFor="isChecked">Checked by admin:</label>
          <input
            type="checkbox"
            id="isChecked"
            name="isChecked"
            checked={!!materialData.isChecked}
            onChange={(e) => setMaterialData({ ...materialData, isChecked: e.target.checked })}
            className="margin-bottom"
          />
        </div>

        <div className="group-submit">
          <button type="submit">{isEditing ? 'Save Edited Material' : 'Create Material'}</button>
        </div>
    </form>
  );
};

export default MaterialForm;
