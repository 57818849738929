import './MaterialDetail.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const MaterialDetail = ({ material, onClose, onUpdate }) => {
    const navigate = useNavigate();

    const handleDeleteMaterial = async (materialId) => {
        try {
            const database = 'materialdatabase';
            const collection = 'materialData';
            const response = await axios.delete(`/api/materials/${database}/${collection}/${materialId}`);
            if (response.status === 200) {
                console.log('Material successfully deleted');
                onClose();
                onUpdate();
            }
        } catch (error) {
            console.error('Error deleting material:', error);
        }
    };

    const handleEdit = () => {
        navigate('/materials/materialform', { state: { material } });
    };

    return (
        <div className="material-detail">
            <div className="material-header">
                <h2>{material.materialName}</h2>
                <div>
                    <button className="header-button" onClick={handleEdit}>Edit</button>
                    <button className="header-button" onClick={onClose}>Close</button>
                </div>
            </div>
            <div className="material-properties-table">
                <div className="material-properties">
                    <p>
                        <strong>Tensile Strength: </strong>
                        {material.tensileStrength} MPa
                    </p>
                    <p>
                        <strong>Thermal Conductivity: </strong>
                        {material.thermalConductivity} W/(m·K)
                    </p>
                    <p>
                        <strong>Elongation: </strong>
                        {material.elongation} %
                    </p>
                    <p>
                        <strong>Chemical Composition: </strong>
                        <ul>
                            {material.chemicalComposition.map((composition, index) => (
                                <li key={index}>{composition.element}: {composition.percentage}%</li>
                            ))}
                        </ul>
                    </p>
                </div>
                <div className="material-other-names">
                    <strong>Other Names: </strong>
                    <ul>
                        {material.otherNames?.length > 0 ? (
                            material.otherNames.map((name, index) => <li key={index}>{name}</li> )
                        ) : (
                            <li>None</li>
                        )}
                    </ul>
                </div>
            </div>
            <p>
                <strong>Material Description:</strong>
                <p>{material.materialDescription}</p>
            </p>
            <p>
                <strong>Is Checked: </strong>
                <p>{material.isChecked ? 'Yes' : 'No'}</p>
            </p>
            <div className="delete-button">
                <button className="header-button" onClick={() => handleDeleteMaterial(material._id)}>Delete</button>           
            </div>
        </div>
    );
};

export default MaterialDetail;
