import React from 'react';
import { Link } from 'react-router-dom';
import './MaterialHeader.css';

const MaterialHeader = () => {
  return (
    <nav className="material-Navbar">
      <div className="material-MenuNavbar">
        <div className="material-MenuNavbar-item">
          <Link to="/materials/listofmaterials">List of materials</Link>
        </div>
        <div className="material-MenuNavbar-item">
          <Link to="/materials/hardnesscomparison">Hardness comparison</Link> 
        </div>
        <div className="material-MenuNavbar-item">
          <Link to="/materials/materialform">Material Form</Link> 
        </div>  
      </div>
    </nav>
  );
};

export default MaterialHeader;
