import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="mainNavbar">
      <div className="leftSection">
        <Link to="/">
          <img src="/favicon/android-chrome-512x512.png" alt="Home" className="homeIcon" />
        </Link>
      </div>
      <div className="mainMenuNavbar">
        <Link to="/materials">Materials</Link>
      </div>
      <div className="loginSection">
        <span className="login-indicator"></span>
        <Link to="/login">Login</Link>
      </div>
    </nav>
  );
};

export default Navbar;
